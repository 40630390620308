import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  getCountries,
  getDakarZones,
  getGPs,
  getRegions,
} from "../../apis/delivery";
import { useCartStore } from "../../stores/cart";
import FormLivraisonMethod from "./FormLivraisonMethod";

const CheckOut = () => {
  const selectedPayment = useCartStore((state) => state.paymentMethod);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const onSubmit = (data) => {
    data.paymentMethod = selectedPayment;
    localStorage.setItem("order", JSON.stringify(data));
    navigate("/order", { state: data });
  };
  const [dakarZones, setDakarZones] = useState([]);
  const [regions, setRegions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [GPs, setGPs] = useState([]);

  useEffect(() => {
    getRegions().then((values) => setRegions(values));
    getDakarZones().then((values) => setDakarZones(values));
    getCountries().then((values) => setCountries(values));
    getGPs().then((values) => setGPs(values));
  }, []);

  return (
    <div className="check_out">
      <form onSubmit={handleSubmit(onSubmit)} className="check_out__form">
        <div>
          <FormLivraisonMethod
            dakarZones={dakarZones}
            countries={countries}
            regions={regions}
            GPs={GPs}
            deliverZone={watch("deliverZone", "senegal")}
            region={watch("region")}
            country={watch("country")}
            GP={watch("GP")}
            active={true}
            errors={errors}
            register={register}
          />
        </div>
        <p className="check_out__form__actions">
          <button
            type="submit"
            className="check_out__form__actions__button"
          >
            Enregistrer
          </button>
        </p>
      </form>
    </div>
  );
};

export default CheckOut;
