import { useToggle } from "usehooks-ts";
import React from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { Link } from "react-router-dom";

const questions = [
  {
    title: "Qu'est ce que DIGIFOIRE?",
    content: `Bienvenue sur DIGIFOIRE, une foire numérique innovante qui offre une plateforme virtuelle pour la présentation, la promotion et la mise en réseau de produits et de services de manière interactive et immersive.
La DIGIFOIRE est une Marketplace en ligne où vous achetez directement auprès des exposants de la foire. C’est une initiative de la startup KATI360 en collaboration avec le CICES (centre international de commerce extérieur du Sénégal).

Une plateforme qui permet aux Sénégalais( e) de l’intérieur du pays et de la diaspora de profiter des opportunités qu’offre le CICES avec ses foires annuelles via le digital.
Elle permet aux marchands de la foire de :
  - Gagner en visibilité comme en opportunités de vente ou de partenariat qui vont au-delà de la foire \n     physique.
  - Atteindre un public mondial grâce à notre plateforme virtuelle, ce qui leur permettra d'élargir leur portée \n     au-delà des frontières habituelles
`,
  },
  {
    title: "Processus de vérification des vendeurs",
    content: `Avant de pouvoir vendre sur la plateforme, les vendeurs doivent fournir des informations vérifiables et respecter nos normes de qualité et d'intégrité.`,
  },
  {
    title: "Livraison suivie",
    content:
      "Nous proposons un acheminement de votre commande sécurisé et suivi par une équipe qui sera mise en disposition de la foire pour gérer les services de livraison après achat en ligne, pour les acheteurs résidant à Dakar, une équipe de GP pour les achats pour l’étranger et pour l’intérieur du pays par le biais de plusieurs transporteurs.",
  },
  {
    title: "Protection de l'acheteur",
    content: `Nous offrons des garanties de protection de l'acheteur pour assurer votre satisfaction. En cas de problème avec une commande, vous pouvez contacter notre service clientèle pour obtenir de l’aide. A l’achat, l’acheteur paie le montant total de la commande en ligne. Ce montant payé par l’acheteur sera séquestré et ne sera remis au vendeur qu’après validation de la livraison par le client.`,
  },
  {
    title: "Pourquoi faire confiance à DIGIFOIRE ?",
    content: `Notre site est reconnu comme fiable et digne de confiance pour effectuer vos achats en ligne de manière sécurisée. Elle représente plus de 55 ans d’expériences cumulées entre l’organisation de foire et le digital
La première foire organisée par la CICES date de 1974.
`,
  },
  {
    title: "How Digifoire works",
    content:
      "lorum ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
  },
  {
    title: "Évaluations et commentaires",
    content:
      "Les acheteurs peuvent laisser des commentaires et évaluations après leurs achats. Ces avis permettent d'avoir une idée de l'expérience d'autres personnes et de prendre une décision éclairée avant de commander.",
  },
];

function Question({ question }) {
  const [hided, toggle] = useToggle(true);

  return (
    <div className="faq__other_questions__question">
      <div className="faq__other_questions__question__title">
        {question.title}
      </div>
      <div
        onClick={() => toggle()}
        className={`faq__other_questions__question__content ${
          hided ? "faq__other_questions__question__content--hide" : ""
        }`}
      >
        {question.content}
        <div className="faq__other_questions__question__content__indicator">
          {hided ? (
            <MdKeyboardArrowDown size={24} />
          ) : (
            <MdKeyboardArrowUp size={24} />
          )}
        </div>
      </div>
    </div>
  );
}

const Faq = () => {
  const [hided, toggle] = useToggle(true);
  const limit = 5;
  const firstQuestion = questions[0];

  return (
    <div className="faq">
      <div>
        <div className="faq__first_question">
          <div className="faq__first_question__title">
            {firstQuestion.title}
          </div>
          <div
            onClick={() => toggle()}
            className={`faq__first_question__content ${
              hided ? "faq__first_question__content--hide" : ""
            }`}
          >
            {firstQuestion.content}
            <div className="faq__first_question__content__indicator">
              {hided ? (
                <MdKeyboardArrowDown size={24} />
              ) : (
                <MdKeyboardArrowUp size={24} />
              )}
            </div>
          </div>
        </div>
        <div className="faq__other_questions">
          {questions.slice(1, limit).map((question, index) => (
            <Question question={question} key={index} />
          ))}
        </div>
        <div className="faq__help">
          <div className="faq__help__title">
            Vous avez une question ? Nous avons des réponses.
          </div>
          <Link className="faq__help__btn" to={"/"}>
            Aller au centre d'aide
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Faq;
