import { create } from 'zustand';

export const useLikeStore = (() => {
  const likedProducts = localStorage.getItem('likedProducts') ?? '[]';

  return create((set) => ({
    likedProducts: JSON.parse(likedProducts),
    addLike: (product) =>
      set((state) => {
        const likedProducts = [...state.likedProducts, product];
        localStorage.setItem('likedProducts', JSON.stringify(likedProducts));
        return { likedProducts };
      }),
    removeLike: (product) =>
      set((state) => {
        console.log(product);
        const likedProducts = state.likedProducts.filter(
          (p) => p.id !== product.id
        );
        localStorage.setItem('likedProducts', JSON.stringify(likedProducts));
        return { likedProducts };
      }),
    toggleLikes: (product) =>
      set((state) => {
        let likedProducts = [...state.likedProducts];
        const index = likedProducts.findIndex((p) => p.id === product.id);
        if (index !== -1) {
          likedProducts = likedProducts.filter((p) => p.id !== product.id);
        } else {
          likedProducts = [...likedProducts, product];
        }
        localStorage.setItem('likedProducts', JSON.stringify(likedProducts));
        return { likedProducts };
      }),
  }));
})();
