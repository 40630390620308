export function useLivraison({ orderInfo, totalWeight }) {
  const fixedPrice = 2000;
  let livraison = 0,
    mode = '';
  if ('GP' in orderInfo && orderInfo.GP && orderInfo.GP !== 'noGP') {
    const GP = JSON.parse(orderInfo.GP);
    livraison = GP.pricePerKilo * GP.XOF * totalWeight;
    mode = { name: 'GP', data: GP };
  } else if (
    'gpName' in orderInfo &&
    'gpPhone' in orderInfo &&
    orderInfo.gpName &&
    orderInfo.gpPhone
  ) {
    mode = { name: 'noGP', data: { name: orderInfo.gpName, phone: orderInfo.gpPhone } };
  } else if ('dakarZone' in orderInfo && orderInfo.dakarZone) {
    const data = JSON.parse(orderInfo.dakarZone);
    livraison = data.price;
    mode = { name: 'dakarZone', data: data };
  } else if ('region' in orderInfo && orderInfo.region) {
    const livraisonData = JSON.parse(orderInfo.region);
    mode = { name: 'region', data: livraisonData };
    for (const key in livraisonData) {
      const regex = /(\d+)-(\d+) KG/;
      if (key.match(regex)) {
        const data = key.match(regex);
        const min = data[1],
          max = data[2];
        if (min < totalWeight && max > totalWeight) {
          livraison = livraisonData[key];
          break;
        }
      }
    }
    if (livraison === 0) {
      const parts = totalWeight / 50;
      livraison = parts * livraisonData['45-50 KG'];
    }
  }

  if (mode.name !== 'dakarZone') livraison += fixedPrice;
  return {
    livraison: livraison,
    mode: mode,
    fixedPrice: mode.name !== 'dakarZone' ? fixedPrice : null,
  };
}
