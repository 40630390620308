import { useState } from 'react';

export default function useSwipper(length = 0) {
  const [index, setIndex] = useState(0);

  function goTo(index) {
    if (index < 0 || index > length - 1) return;

    setIndex(index);
  }

  function next() {
    if (index === length - 1) {
      setIndex(0);
      return;
    }

    setIndex(index + 1);
  }

  function prev() {
    if (index === 0) {
      setIndex(length - 1);
      return;
    }

    setIndex(index - 1);
  }

  return {
    index,
    goTo,
    next,
    prev,
  };
}
