import React, { Fragment, useEffect, useState } from "react";
import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import { useFetch } from "usehooks-ts";
import { API_URL } from "../../constants/api";
import useHorizontalScroll from "../../hooks/useHorizontalScroll";

const TopAnnounces = () => {
  const scrollStep = 360;
  const elementsPerPage = 6;
  const { data: top_announces } = useFetch(
    `${API_URL}/products?_sort=saleCount&_order=desc`
  );
  const { ref, scrollWidth, currentScroll, handleScroll } =
    useHorizontalScroll();
  const [hasNext, setHasNext] = useState(false);
  const [hasPrev, setHasPrev] = useState(false);
  const [visibleAnnounces, setVisibleAnnounces] = useState(elementsPerPage);

  useEffect(() => {
    setHasNext(currentScroll + 5 < scrollWidth && scrollWidth > 0);
    setHasPrev(currentScroll > 0);
  }, [currentScroll, scrollWidth]);
  const handleSeeMore = () => {
    setVisibleAnnounces((prev) => prev + elementsPerPage);
  };
  return (
    <Fragment>
      <div className="top_announces_container">
        <div
          className="top_announces top_announces_desktop  hide_scroll"
          ref={ref}
          onScroll={handleScroll}
        >
          {top_announces?.data.map((announces, index) => (
            <Link
              to={`/details/${announces.id}`}
              key={index}
              className="top_announces__announce"
            >
              <div className="top_announces__announce__image">
                <div
                  style={{ backgroundImage: `url('${announces?.image}')` }}
                ></div>
              </div>
              <div className="top_announces__announce__title">
                <p>{announces.name}</p>
              </div>
            </Link>
          ))}
        </div>

        <div className="top_announces_mobile">
          {top_announces?.data
            .slice(0, visibleAnnounces)
            .map((announces, index) => (
              <Link
                to={`/details/${announces.id}`}
                key={index}
                className="top_announces__announce"
              >
                <div className="top_announces__announce__image">
                  <div
                    style={{ backgroundImage: `url('${announces?.image}')` }}
                  ></div>
                </div>
                <div className="top_announces__announce__title">
                  <p>{announces.name}</p>
                </div>
              </Link>
            ))}
        </div>

        <button
          onClick={() => (ref.current.scrollLeft -= scrollStep)}
          className={`top_announces_container__btn top_announces_container__btn--left ${
            hasPrev ? "top_announces_container__btn--active" : ""
          }`}
        >
          <BiLeftArrowAlt />
        </button>
        <button
          onClick={() => (ref.current.scrollLeft += scrollStep)}
          className={`top_announces_container__btn top_announces_container__btn--right ${
            hasNext ? "top_announces_container__btn--active" : ""
          }`}
        >
          <BiRightArrowAlt />
        </button>
      </div>
      <div className="see_more_container">
        <button onClick={handleSeeMore} className="see_more">
          Voir Plus
        </button>
      </div>
    </Fragment>
  );
};

export default TopAnnounces;
