import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuthStore } from '../stores/useAuthStore';

const AuthLayout = () => {
  const navigate = useNavigate();
  const user = useAuthStore((state) => state.user);
  const costumer = useAuthStore((state) => state.costumer);
  const authLoading = useAuthStore((state) => state.loading);

  useEffect(() => {
    if (!authLoading && user && (!user?.displayName || !costumer)) navigate('/auth/register');
    return () => {};
  }, [authLoading, costumer, navigate, user]);

  return (
    <div className="auth_layout">
      <Outlet />
      <Toaster
        position="bottom-center"
        toastOptions={{
          duration: 3000,
          style: {
            background: '#363636',
            color: '#fff',
          },
        }}
      />
    </div>
  );
};
export default AuthLayout;
