import React, { Fragment, useEffect, useState } from 'react';
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi';
import { useFetch } from 'usehooks-ts';
import { API_URL } from '../../constants/api';
import useHorizontalScroll from '../../hooks/useHorizontalScroll';

const OurPartners = () => {
  const scrollStep = 360;
  const { data: partners } = useFetch(`${API_URL}/partners`);
  const { ref, scrollWidth, currentScroll, handleScroll } = useHorizontalScroll();
  const [hasNext, setHasNext] = useState(false);
  const [hasPrev, setHasPrev] = useState(false);

  useEffect(() => {
    setHasNext(currentScroll < scrollWidth && scrollWidth > 0);
    setHasPrev(currentScroll > 0);
  }, [currentScroll, scrollWidth]);

  return (
    <div className="our_partners">
      <div className="our_partners__toolbar">
        <div className="our_partners__toolbar__title">Nos partenaires</div>
        <div className="our_partners__toolbar__action">
          {(hasNext || hasPrev) && (
            <Fragment>
              <button
                onClick={() => (ref.current.scrollLeft -= scrollStep)}
                className={`our_partners__toolbar__action__btn ${
                  hasPrev ? 'our_partners__toolbar__action__btn--active' : ''
                }`}
              >
                <BiLeftArrowAlt />
              </button>
              <button
                onClick={() => (ref.current.scrollLeft += scrollStep)}
                className={`our_partners__toolbar__action__btn ${
                  hasNext ? 'our_partners__toolbar__action__btn--active' : ''
                }`}
              >
                <BiRightArrowAlt />
              </button>
            </Fragment>
          )}
        </div>
      </div>
      <div ref={ref} onScroll={handleScroll} className="our_partners__list hide_scroll">
        {partners?.data.map((partner, index) => (
          <div
            style={{
              backgroundImage: `url(${partner.logo})`,
            }}
            className="our_partners__list__item"
            key={partner.name}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default OurPartners;
