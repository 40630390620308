import { createBrowserRouter } from 'react-router-dom';
import AuthLayout from '../layouts/AuthLayout';
import MainLayout from '../layouts/MainLayout';
import PageNotFound from '../pages/404/PageNotFound';
import LoginPage from '../pages/auth/LoginPage';
import RegisterPage from '../pages/auth/RegisterPage';
import CheckOut from '../pages/check-out/CheckOut';
import DetailsPage from '../pages/details/DetailsPage';
import HomePage from '../pages/home/HomePage';
import ShoppingCartPage from '../pages/shopping-cart/ShoppingCartPage';
import OrderPage from '../pages/validate-order/OrderPage';
import VerifyPage from '../pages/auth/VerifyPage';
import ProductsByCategoryPage from '../pages/products-by-category/ProductsByCategoryPage';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: '/', element: <HomePage /> },
      { path: '/details/:id', element: <DetailsPage /> },
      { path: '/shopping-cart', element: <ShoppingCartPage /> },
      { path: '/order', element: <OrderPage /> },
      { path: '/check-out', element: <CheckOut /> },
      { path: '/category/:categoryName', element: <ProductsByCategoryPage /> }
    ],
  },
  {
    path: '/auth',
    element: <AuthLayout />,
    children: [
      { path: 'login', element: <LoginPage /> },
      {
        path: 'register',
        element: <RegisterPage />,
      },
      {
        path: 'verify',
        element: <VerifyPage />,
      },
    ],
  },
  {
    path: '*',
    element: <PageNotFound />,
  },
]);
