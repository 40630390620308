import { useNavigate, useParams } from 'react-router-dom';
import { getProductsByCategory } from '../../apis/products';
import { useEffect, useState } from 'react';
import Product from '../../components/product/Product';
import { getCategories } from '../../apis/categories';

function ProductsByCategoryPage() {
  const { categoryName } = useParams();
  const [products, setProducts] = useState(null);
  let navigate = useNavigate();

  useEffect(() => {
    getCategories().then((categories) => {
      const category = categories?.data.find((item) =>
        item.name.toLowerCase() === categoryName.toLowerCase()
      );
      getProductsByCategory(category.id)
        .then((products) => {console.log(products);
          if (products.data) {
            setProducts(products.data);
          }
        })
        .catch((err) => {
          console.log(err);
          navigate('/404');
        });
    });
  }, [categoryName, navigate]);

  return (
    <div className="associated-products-page">
      <h3>Catégorie : {categoryName}</h3>
      <div className="associated-products-page__products">
        {products?.map((product) => (
          <Product key={product.id} product={product} />
        ))}
      </div>
    </div>
  );
}

export default ProductsByCategoryPage;
