import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../stores/useAuthStore';

const VerifyPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const setUser = useAuthStore((state) => state.setUser);
  const [otp, setOtp] = useState('');

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    window.confirmationResult
      .confirm(otp)
      .then((result) => {
        setUser(result.user);
        setLoading(false);
        if (result.user.displayName) return navigate('/');
        navigate('/auth/register');
      })
      .catch((error) => {
        console.log('error', error);
        setLoading(false);
      });
  };

  return (
    <div className="wrapper">
      <form id="box" onSubmit={onSubmit}>
        <img src="/static/media/logo.767b77d1d0e0ecfcfa29781337537bbc.svg" alt="lock" />
        <h3>Enter the code sent to your phone</h3>
        <div className="inputs">
          <OtpInput
            containerStyle="otp-input"
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
          />
        </div>
        <input
          style={{
            marginTop: '2rem',
          }}
          disabled={loading}
          type="submit"
          value={loading ? 'Loading...' : 'Confirmer'}
        />
      </form>
    </div>
  );
};
export default VerifyPage;
