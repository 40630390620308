import React from 'react';
import { Link } from 'react-router-dom';
import { useFetch } from 'usehooks-ts';
import RecentBgImage from '../assets/images/recent bg.png';
import { API_URL } from '../constants/api';
import Product from './product/Product';

const RecentlyConsulted = () => {
  const { data: products } = useFetch(`${API_URL}/products?_sort=createdAt&_order=desc`);
  const max = 6;

  return (
    <div className="recently_consulted">
      <div className="recently_consulted__toolbar">
        <div className="recently_consulted__toolbar__title">Récemment consulté et plus</div>
        <div className="recently_consulted__toolbar__action"></div>
      </div>
      <div className="recently_consulted__list">
        <div
          style={{
            backgroundImage: `url('${RecentBgImage}')`,
          }}
          className="recently_consulted__list__big_item"
        >
          <div className="recently_consulted__list__big_item__title">
            Regardez les <b>produits</b> les plus consultés que nous avons à <b>offrir</b>.
          </div>
          <div className="recently_consulted__list__big_item__btn">
            <Link to="/">Explorer</Link>
          </div>
        </div>
        <div className="recently_consulted__list__small_items">
          {products?.data.slice(0, max).map((product) => (
            <Product key={product?.id} product={product} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default RecentlyConsulted;
