import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { useFetch } from "usehooks-ts";
import { API_URL } from "../../constants/api";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Controller, Autoplay } from "swiper/modules";

const Hero = () => {
  const { data: heroData } = useFetch(`${API_URL}/products`);
  const [controlledSwiper, setControlledSwiper] = useState(null);

  const productsWithLinkLeft = heroData?.data.filter((item) => item.image_hero);

  const productsWithLinkRight = heroData?.data.filter(
    (item) => item.swiper_droite
  );

  return (
    <div className="hero">
      {heroData && (
        <Fragment>
          <Swiper
            className="hero__left"
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={50}
            slidesPerView={1}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{ clickable: true }}
          >
            {productsWithLinkLeft.map((item, i) => (
              <SwiperSlide key={item?.id}>
                <div className={`hero__left__bg_image`}>
                  <img src={item?.image_hero} alt="" />
                  <div className="hero__left__btn">
                    <Link to={`/details/${item?.id}`}>Acheter</Link>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <Swiper
            className="hero__right"
            modules={[Navigation, Pagination, Controller]}
            spaceBetween={50}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
            onSwiper={setControlledSwiper}
            controller={{ control: controlledSwiper }}
          >
            {productsWithLinkRight.map((ad, i) => (
              <SwiperSlide key={ad?.id}>
                <p key={i} className={`hero__right__text`}>
                  {ad?.message}
                </p>
                <div className="hero__right__btn">
                  <Link to={`/details/${ad?.id}`}>Acheter</Link>
                </div>
                <div className="hero__right__bg_image">
                  <img src={ad?.swiper_droite} alt="" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </Fragment>
      )}
    </div>
  );
};

export default Hero;
