import React, { useEffect } from 'react';
import { AiOutlineEye, AiOutlineHeart } from 'react-icons/ai';
import { FaHeart } from 'react-icons/fa';
import { GrLocation } from 'react-icons/gr';
import { LuRepeat } from 'react-icons/lu';
import { Link, useNavigate } from 'react-router-dom';
import { API_URL } from '../../constants/api';
import usePriceFormatter from '../../hooks/usePriceFormatter';
import { fetchApi } from '../../libs/fetch';
import { useLikeStore } from '../../stores/product';
import { useAuthStore } from '../../stores/useAuthStore';

const Product = ({ product }) => {
  const navigate = useNavigate();
  const price = usePriceFormatter(product?.price);
  const costumer = useAuthStore((state) => state.costumer);
  const addLike = useLikeStore((state) => state.addLike);
  const removeLike = useLikeStore((state) => state.removeLike);
  const likedProducts = useLikeStore((state) => state.likedProducts);
  const exist = likedProducts.find((p) => p.id === product?.id);

  useEffect(() => {
    const likes = product?.likes ?? [];
    const liked = likes.includes(costumer?.id);
    if (liked && !exist) addLike(product);

    return () => {};
  }, [addLike, costumer, exist, product]);

  async function like() {
    try {
      if (!costumer) return navigate('/auth/login');
      if (!exist) addLike(product);
      else removeLike(product);
      await fetchApi(`${API_URL}/products/${product.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          likes: exist
            ? (product?.likes ?? []).filter((id) => id !== costumer?.id)
            : [...(product?.likes ?? []), costumer?.id],
        }),
      });
    } catch (error) {
      console.log(error);
      if (!exist) removeLike(product);
      else addLike(product);
    }
  }

  return (
    <div className="product">
      <div className="product__indicator">{product?.category}</div>
      <div onClick={() => navigate(`/details/${product?.id}`)}>
        <div className="product__top">
          <div
            style={{
              backgroundImage: `url('${product.image}')`,
            }}
            className="product__top__image"
          ></div>

          <Link to={`/details/${product?.id}`} className="product__top__title">
            {product?.name}
          </Link>
          <div className="product__top__location">
            <i>
              <GrLocation />
            </i>
            <span>{product?.location ?? 'Dakar'}</span>
          </div>
          {product?.price && <div className="product__top__price">{price}</div>}
          {product?.weight && <div className="product__top__weight">{`${product?.weight} KG`}</div>}
        </div>
      </div>
      <div className="product__bottom">
        <div className="product__bottom__action">
          <button className="product__bottom__action__btn">
            <AiOutlineEye size={17} />
          </button>
          <button className="product__bottom__action__btn">
            <LuRepeat size={17} />
          </button>
          <button className="product__bottom__action__btn" onClick={like}>
            {exist ? <FaHeart color="#3140af" /> : <AiOutlineHeart size={17} />}
          </button>
        </div>
        {/* <div className="product__bottom__vues">1020 Vues</div> */}
      </div>
    </div>
  );
};

export default Product;
