import { useCounter } from "@uidotdev/usehooks";
import React, { useEffect } from "react";
import { FaCheck, FaHeart } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import usePriceFormatter from "../../hooks/usePriceFormatter";
import { useCartStore } from "../../stores/cart";
import { useLikeStore } from "../../stores/product";
import { useAuthStore } from "../../stores/useAuthStore";
import { API_URL } from "../../constants/api";
import { fetchApi } from "../../libs/fetch";
import { AiOutlineHeart } from "react-icons/ai";

function ProductDetails({ product }) {
  const { cart, addToCart, decreaseQuantity, increaseQuantity } = useCartStore(
    (state) => state
  );
  const costumer = useAuthStore((state) => state.costumer);
  const addLike = useLikeStore((state) => state.addLike);
  const removeLike = useLikeStore((state) => state.removeLike);
  const likedProducts = useLikeStore((state) => state.likedProducts);
  const exist = likedProducts.find((p) => p.id === product?.id);
  const item = cart?.find((item) => item?.id === product?.id);
  const navigate = useNavigate();

  const [quantity, { increment, decrement, set }] = useCounter(
    item?.quantity ?? 1,
    {
      min: 1,
      max: 10,
    }
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    set(item?.quantity ?? 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);
  useEffect(() => {
    const likes = product?.likes ?? [];
    const liked = likes.includes(costumer?.id);
    if (liked && !exist) addLike(product);

    return () => {};
  }, [addLike, costumer, exist, product]);

  async function like() {
    try {
      if (!costumer) return navigate("/auth/login");
      if (!exist) addLike(product);
      else removeLike(product);
      const res = await fetchApi(`${API_URL}/products/${product.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          likes: exist
            ? (product?.likes ?? []).filter((id) => id !== costumer?.id)
            : [...(product?.likes ?? []), costumer?.id],
        }),
      });
      console.log(res);
    } catch (error) {
      console.log(error);
      if (!exist) removeLike(product);
      else addLike(product);
    }
  }
  function handleAddToCart() {
    const item = cart?.find((item) => item?.id === product?.id);
    if (item) return;
    addToCart({ ...product, quantity });
  }

  const formattedPrice = usePriceFormatter(product?.price, "fr-FR", "cfa", 0);

  return (
    <div className="details">
      <div className="details__products">
        <img src={product?.image} alt="" />
      </div>
      <div className="details__description">
        <div className="details__description__stock">
          <span className={product?.stock ? "--in_stock " : "--out_of_stock"}>
            {product?.stock ? "En stock" : "En rupture"}
          </span>
        </div>
        <div className="details__description__title">
          <h3>{product?.name}</h3>
        </div>
        <div className="details__description__faq">
          {product?.additional_infos?.map((info, i) => {
            return (
              <p key={i}>
                <FaCheck color={"#3140AF"} /> &nbsp; {info?.title}
              </p>
            );
          })}
        </div>
        <div className="details__description__price">
          <h3>{formattedPrice}</h3>
          {product?.weight && <p>{`${product?.weight} KG`}</p>}
        </div>
        <div className="details__description__bottom">
          <p>Quantité</p>
          <div className="details__description__bottom__quantity_container">
            <div className="details__description__bottom__quantity_container__quantity">
              <button
                className="minus"
                aria-label="Decrease"
                onClick={() => {
                  decrement();

                  if (item) decreaseQuantity(item?.id);
                }}
              >
                &minus;
              </button>
              <input
                type="number"
                className="input-box"
                value={quantity}
                readOnly
                min="1"
                max="10"
              />
              <button
                className="plus"
                aria-label="Increase"
                onClick={() => {
                  increment();

                  if (item) increaseQuantity(item?.id);
                }}
              >
                &#43;
              </button>
            </div>
            <div className="details__description__bottom__quantity_container__add_to_cart">
              {!item && product.stock && (
                <button onClick={handleAddToCart}>Ajouter au panier</button>
              )}
            </div>
            <div className="details__description__bottom__quantity_container__heart">
              <span>
                <span onClick={like}>
                  {exist ? (
                    <FaHeart color="#3140af" />
                  ) : (
                    <AiOutlineHeart size={17} />
                  )}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDetails;
