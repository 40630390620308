import { create } from 'zustand';

export const useCartStore = (() => {
  const cartData = localStorage.getItem('cart') || '[]';
  const paymentMethod = localStorage.getItem('paymentMethod') || null;

  return create((set) => ({
    cart: JSON.parse(cartData),
    paymentMethod: JSON.parse(paymentMethod),
    setPaymentMethod: (method) => {
      localStorage.setItem('paymentMethod', JSON.stringify(method));
      set({ paymentMethod: method });
    },
    addToCart: (product) => {
      const cart = JSON.parse(localStorage.getItem('cart') || '[]');
      const newCart = [...cart, product];
      localStorage.setItem('cart', JSON.stringify(newCart));
      set({ cart: newCart });
    },
    increaseQuantity: (id) => {
      const cart = JSON.parse(localStorage.getItem('cart') || '[]');
      const newCart = cart.map((product) => {
        if (product.id === id) {
          return { ...product, quantity: product.quantity + 1 };
        }
        return product;
      });
      localStorage.setItem('cart', JSON.stringify(newCart));
      set({ cart: newCart });
    },
    decreaseQuantity: (id) => {
      const cart = JSON.parse(localStorage.getItem('cart') || '[]');
      const newCart = cart.map((product) => {
        if (product.id === id) {
          if (product.quantity === 1) return product;
          return { ...product, quantity: product.quantity - 1 };
        }
        return product;
      });
      localStorage.setItem('cart', JSON.stringify(newCart));
      set({ cart: newCart });
    },

    remove: (id) => {
      const cart = JSON.parse(localStorage.getItem('cart') || '[]');
      const newCart = cart.filter((product) => product.id !== id);
      localStorage.setItem('cart', JSON.stringify(newCart));
      set({ cart: newCart });
    },

    clear: () => {
      localStorage.removeItem('cart');
      set({ cart: [] });
    },
  }));
})();
