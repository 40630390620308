import React, { useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
/* import { BiPlus } from 'react-icons/bi'; */
import { BsCart4 } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { useFetch } from 'usehooks-ts';
import { searchProductByName } from '../../apis/products';
import LogoImage from '../../assets/images/logo.svg';
import { API_URL } from '../../constants/api';
import { auth } from '../../libs/firebase';
import { useCartStore } from '../../stores/cart';
import { useAuthStore } from '../../stores/useAuthStore';

const Header = () => {
  const { data: categories } = useFetch(`${API_URL}/categories`);
  const cart = useCartStore((state) => state.cart);
  const user = useAuthStore((state) => state.user);
  const [search, setSearch] = useState("");
  const [filtered, setFiltered] = useState([]);
  const retrieveData = async (name) =>
    name ? (await searchProductByName(name)).data : [];
  const handleSearch = (e) => {
    const value = e.target.value.trim();
    setSearch(value);
    retrieveData(value).then((result) => setFiltered(result));
  };
  const authLoading = useAuthStore((state) => state.loading);

  return (
    <header className="header">
      <div className="header__top">
        {/* <div className="header__top__btn">
          <Link to="">Devenir Vendeur</Link>
          <BiPlus />
        </div> */}
      </div>
      <div className="header__middle">
        <div className="header__middle__logo">
          <Link to="/">
            <img src={LogoImage} alt="logo" />
          </Link>
        </div>
        <div className="header__middle__search">
          <div className="header__middle__search__input">
            <input
              type="search"
              placeholder="Rechercher un produits"
              value={search}
              onChange={handleSearch}
            />
            <span>
              <AiOutlineSearch />
            </span>
          </div>
          <div className="header__middle__search__suggestion">
            <div
              className="header__middle__search__suggestion__list"
              style={{ display: filtered.length === 0 ? "none" : "block" }}
            >
              {filtered.map((item, index) => (
                <div
                  key={index}
                  className="header__middle__search__suggestion__item"
                >
                  <Link
                    to={`/details/${item.id}`}
                    onClick={() => setFiltered([])}
                  >
                    {item.name}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="header__middle__profile">
          <div className="header__middle__profile__login">
            {!user && !authLoading && (
              <Link to="/auth/login">Se connecter</Link>
            )}
            {user && !authLoading && (
              <Link
                style={{ textTransform: "capitalize" }}
                to="#"
                onClick={() => auth.signOut()}
              >
                Bonjour, {user?.displayName}
              </Link>
            )}
            {authLoading && <Link to="#">Chargement...</Link>}
          </div>
          <div className="header__middle__profile__cart">
            <Link to="/shopping-cart">
              <BsCart4 />
            </Link>
            {cart.length !== 0 && (
              <div className="header__middle__profile__cart__badge">
                {cart.length}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="header__bottom">
        {categories?.data.map((category, index) => (
          <Link
            key={category?.name}
            to={`/category/${category?.name}`}
            className="header__bottom__link"
          >
            {category?.name.replace(/-|_/g, " ")}
          </Link>
        ))}
      </div>
    </header>
  );
};

export default Header;
