import React from "react";
import { FaCheck } from "react-icons/fa";

function OrderItems({ quantity, increment, decrement }) {
  const carts = JSON.parse(localStorage.getItem("cart"));
  return (
    <div className="order_items">
      {carts?.map((product, i) => (
        <div key={i} className="order_items__item">
          <div className="order_items__item__image">
            <img src={product?.image} alt="" />
          </div>
          <div className="order_items__item__description">
            <div className="order_items__item__description__name">
              <p>{product?.name}</p>
            </div>
            <div className="order_items__item__description__info">
              {product?.additional_infos?.map((info, i) => {
                return (
                  <p key={i}>
                    <FaCheck color={"#3140AF"} /> &nbsp; {info?.title}
                  </p>
                );
              })}
            </div>
          </div>
          <div className="order_items__item__details">
            <div className="order_items__item__details__quantity_container">
              <p>Quantité</p>
              <div className="order_items__item__details__quantity_container__quantity">
                <button
                  className="minus"
                  aria-label="Decrease"
                  onClick={decrement}
                >
                  &minus;
                </button>
                <input
                  type="number"
                  className="input-box"
                  value={quantity + product?.quantity - 1}
                  readOnly
                  min="1"
                  max="10"
                />
                <button
                  className="plus"
                  aria-label="Increase"
                  onClick={increment}
                >
                  &#43;
                </button>
              </div>
            </div>
            <div className="order_items__item__details__price">
              <h3>
                {product?.price * (product?.quantity + quantity - 1)}
                CFA
              </h3>
              {product?.weight && (
                <p style={{ padding: 0 }}>{`${product?.weight} KG`}</p>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default OrderItems;
