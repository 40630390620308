import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { isValidNumber } from 'libphonenumber-js';
import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../libs/firebase';
import { useAuthStore } from '../../stores/useAuthStore';

const LoginPage = () => {
  const navigate = useNavigate();
  const user = useAuthStore((state) => state.user);
  const loadingUser = useAuthStore((state) => state.loading);
  const costumer = useAuthStore((state) => state.costumer);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    setupRecaptcha(auth);
    if (!isValidNumber(`+${phoneNumber}`)) return;
    setLoading(true);
    signInWithPhoneNumber(auth, `+${phoneNumber}`, window.recaptchaVerifier)
      .then((res) => {
        window.confirmationResult = res;
        window.recaptchaVerifier.clear();
        navigate('/auth/verify');
      })
      .catch((error) => {
        console.log('Login error :', error);
        setLoading(false);
      });
  };

  function setupRecaptcha(auth) {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
      size: 'invisible',
      callback: function (response) {
        console.log('Recaptcha resolved', response);
      },
      'expired-callback': function () {},
    });
  }

  useEffect(() => {
    if (!loadingUser && user) return navigate('/');
    return () => {};
  }, [costumer, loadingUser, navigate, user]);


  return (
    <div className="wrapper">
      <form id="box" onSubmit={onSubmit}>
        <img src="/static/media/logo.767b77d1d0e0ecfcfa29781337537bbc.svg" alt="lock" />
        <h3>Please sign in</h3>
        <div className="inputs">
          <PhoneInput
            country={'sn'}
            value={phoneNumber}
            onChange={(phone) => setPhoneNumber(phone)}
            containerClass="phone-container"
            inputClass="phone-input"
            buttonClass="phone-button"
          />
        </div>
        <input
          style={{
            marginTop: '2rem',
          }}
          disabled={loading}
          type="submit"
          value={loading ? 'Loading...' : 'Continuer'}
        />
        <div className="center padding">
          <div id="recaptcha-container"></div>
        </div>
      </form>
    </div>
  );
};
export default LoginPage;
