import React, { Fragment } from "react";
import { useFetch } from "usehooks-ts";
import RecentlyConsulted from "../../components/RecentlyConsulted";
import Products from "../../components/product/Products";
import { API_URL } from "../../constants/api";
import Faq from "./Faq";
import Hero from "./Hero";
import MiddleBanner from "./MiddleBanner";
import OurPartners from "./OurPartners";
import Reduction from "./Reduction";
import TopAnnounces from "./TopAnnounces";
import BestSeller from "./BestSeller";

const HomePage = () => {
  const { data: products } = useFetch(`${API_URL}/products`);
  const { data: topSellers } = useFetch(
    `${API_URL}/agents?filtre={"type":"sellers"}&_start=0&_end=10&_sort=saleCount&_order=desc`
  );

  return (
    <Fragment>
      <Reduction />
      <Hero />
      <TopAnnounces />
      <MiddleBanner />
      <div className="featured_ads">
        <Products title="Annonces en vedette" products={products?.data} />
      </div>
      <div className="recently_consulted_and_more">
        <RecentlyConsulted />
      </div>
      <div className="best_sales">
        <BestSeller title="Vendeurs en vue" products={topSellers?.data} />
      </div>
      <OurPartners />
      <div className="home_faq">
        <Faq />
      </div>
    </Fragment>
  );
};

export default HomePage;
