import React, { Fragment } from "react";
import toast from "react-hot-toast";
import { GoTrash } from "react-icons/go";
import { Link, useNavigate } from "react-router-dom";
import { useFetch } from "usehooks-ts";
import EmptyCartImage from "../../assets/images/empty-cart.svg";
import AssociatedProducts from "../../components/associated-products/AssociatedProducts";
import { API_URL } from "../../constants/api";
import usePriceFormatter from "../../hooks/usePriceFormatter";
import { useCartStore } from "../../stores/cart";

const CardItem = ({ item }) => {
  const increaseQuantity = useCartStore((state) => state.increaseQuantity);
  const decreaseQuantity = useCartStore((state) => state.decreaseQuantity);
  const remove = useCartStore((state) => state.remove);
  const price = usePriceFormatter(item?.price * item?.quantity);

  return (
    <div className="shopping_cart__content__items__item">
      <div>
        <div className="shopping_cart__content__items__item__remove">
          <button onClick={() => remove(item?.id)}>
            <GoTrash />
          </button>
        </div>
      </div>
      <div>
        <div className="shopping_cart__content__items__item__image">
          <img src={item?.image} alt="" />
        </div>
        <div className="shopping_cart__content__items__item__title">
          {item?.name}
        </div>
        <div className="shopping_cart__content__items__item__price">
          {price}
          {item?.weight && (
            <p className="shopping_cart__content__items__item__weight">
              {`${item?.weight} KG`}
            </p>
          )}
        </div>
        <div className="shopping_cart__content__items__item__quantity">
          <div className="shopping_cart__content__items__item__quantity__title">
            Quantité
          </div>
          <div className="shopping_cart__content__items__item__quantity__control">
            <button onClick={() => decreaseQuantity(item?.id)}>-</button>
            <input type="text" value={item?.quantity} readOnly />
            <button onClick={() => increaseQuantity(item?.id)}>+</button>
          </div>
        </div>
      </div>
    </div>
  );
};

const ShoppingCartPage = () => {
  const navigate = useNavigate();
  const { data: paymentMethods } = useFetch(`${API_URL}/payment-methods`);
  const items = useCartStore((state) => state.cart);
  const selectedPayment = useCartStore((state) => state.paymentMethod);
  const setSelectedPayment = useCartStore((state) => state.setPaymentMethod);

  const price = usePriceFormatter(
    items?.reduce((acc, item) => acc + item?.price * item?.quantity, 0)
  );

  const purchase = (e) => {
    e.preventDefault();

    if (items.length === 0) {
      toast.error("Votre panier est vide");
      return;
    }

    navigate("/check-out");
  };

  return (
    <Fragment>
      <div className="shopping_cart_bg">
        <div className="shopping_cart">
          <div className="shopping_cart__toolbar">
            <div className="shopping_cart__toolbar__title">Panier</div>
            <div className="shopping_cart__toolbar__action"></div>
          </div>
          <div className="shopping_cart__content">
            <div className="shopping_cart__content__items">
              {items.length !== 0 ? (
                items.map((item) => <CardItem key={item.id} item={item} />)
              ) : (
                <div className="center">
                  <img src={EmptyCartImage} width={720} alt="" />
                </div>
              )}
            </div>
            <div className="shopping_cart__content__payment">
              <div className="shopping_cart__content__payment__card">
                <div className="shopping_cart__content__payment__card__title">
                  Comment allez-vous payer
                </div>
                <div className="shopping_cart__content__payment__card__options">
                  {paymentMethods?.data?.map((method) => (
                    <div
                      key={method?.id}
                      onClick={() => setSelectedPayment(method)}
                      className={`shopping_cart__content__payment__card__options__option ${
                        method?.id === selectedPayment?.id
                          ? "shopping_cart__content__payment__card__options__option--selected"
                          : ""
                      }`}
                    >
                      <div></div>
                      <img src={method.image} alt="" />
                      {method?.name}
                    </div>
                  ))}
                </div>
                <div className="shopping_cart__content__payment__card__price">
                  <div>Total des articles</div>
                  <div>{price}</div>
                </div>
                <div className="shopping_cart__content__payment__card__purchase">
                  <Link
                    style={{
                      pointerEvents:
                        selectedPayment && items.length !== 0 ? "auto" : "none",
                    }}
                    onClick={purchase}
                  >
                    Passer à la caisse
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AssociatedProducts />
    </Fragment>
  );
};

export default ShoppingCartPage;
