import { updateProfile } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../constants/api';
import { fetchApi } from '../../libs/fetch';
import { useAuthStore } from '../../stores/useAuthStore';

const RegisterPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const user = useAuthStore((state) => state.user);
  const loadingUser = useAuthStore((state) => state.loading);
  const costumer = useAuthStore((state) => state.costumer);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (!loadingUser && !user) return navigate('/auth/login');
    if (!loadingUser && costumer) return navigate('/');
    return () => {};
  }, [costumer, loadingUser, navigate, user]);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const response = await fetchApi(`${API_URL}/auth/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...data, uid: user.uid, phone: user.phoneNumber, type: 'customer' }),
      });

      if ('user' in response) {
        await updateProfile(user, {
          displayName: data.name,
          email: data.email,
        });

        window.location.href = '/';
        return toast.success('Account created successfully!');
      }

      throw new Error('Something went wrong!');
    } catch (error) {
      setLoading(false);
      toast.error(error?.message);
    }
  };

  return (
    <div className="wrapper">
      <form id="box" onSubmit={handleSubmit(onSubmit)}>
        <img src="/static/media/logo.767b77d1d0e0ecfcfa29781337537bbc.svg" alt="lock" />
        <h3>Finalize your account</h3>
        <div className="inputs">
          <input
            type="text"
            placeholder="Khadim Fall"
            aria-invalid={errors.name ? 'true' : 'false'}
            {...register('name', {
              required: true,
              maxLength: 50,
              pattern: /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
            })}
          />
          {errors.name?.type === 'required' && <p role="alert">username is required</p>}
          {errors.name?.type === 'maxLength' && <p role="alert">username is too long</p>}
          {errors.name?.type === 'pattern' && <p role="alert">username is not valid</p>}
          <input
            type="email"
            placeholder="khadimfall02@gmail.com"
            aria-invalid={errors.email ? 'true' : 'false'}
            {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
          />
          {errors.email?.type === 'required' && <p role="alert">email is required</p>}
          {errors.email?.type === 'pattern' && <p role="alert">email is not valid</p>}
          <input
            type="text"
            placeholder="Dakar, Senegal"
            aria-invalid={errors.location ? 'true' : 'false'}
            {...register('location', { required: true })}
          />
          {errors.location?.type === 'required' && <p role="alert">address is required</p>}
        </div>
        <input
          style={{ marginTop: '2rem' }}
          disabled={loading || loadingUser}
          type="submit"
          value={loading ? 'Loading...' : 'Sign up'}
        />
      </form>
    </div>
  );
};
export default RegisterPage;
