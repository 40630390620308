import React, { useEffect, useState } from "react";
import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";
import useHorizontalScroll from "../../hooks/useHorizontalScroll";
import { AiOutlineEye, AiOutlineHeart } from "react-icons/ai";
import { FaHeart } from "react-icons/fa";
import { GrLocation } from "react-icons/gr";
import { LuRepeat } from "react-icons/lu";
import { Link, useNavigate } from "react-router-dom";
import { API_URL } from "../../constants/api";
import usePriceFormatter from "../../hooks/usePriceFormatter";
import { fetchApi } from "../../libs/fetch";
import { useLikeStore } from "../../stores/product";
import { useAuthStore } from "../../stores/useAuthStore";

function BestSeller({ products, title }) {
  const scrollStep = 360;
  const { ref, scrollWidth, currentScroll, handleScroll } =
    useHorizontalScroll();
  const [hasNext, setHasNext] = useState(false);
  const [hasPrev, setHasPrev] = useState(false);
  useEffect(() => {
    setHasNext(currentScroll < scrollWidth && scrollWidth > 0);
    setHasPrev(currentScroll > 0);
  }, [currentScroll, scrollWidth]);
  return (
    <div className="products">
      <div className="products__toolbar">
        <h4 className="products__toolbar__title">{title}</h4>
        <div className="products__toolbar__paginator">
          <button
            onClick={() => (ref.current.scrollLeft -= scrollStep)}
            className={`products__toolbar__paginator__btn ${
              hasPrev ? "products__toolbar__paginator__btn--active" : ""
            }`}
          >
            <BiLeftArrowAlt />
          </button>
          <button
            onClick={() => (ref.current.scrollLeft += scrollStep)}
            className={`products__toolbar__paginator__btn ${
              hasNext ? "products__toolbar__paginator__btn--active" : ""
            }`}
          >
            <BiRightArrowAlt />
          </button>
        </div>
      </div>
      <div
        onScroll={handleScroll}
        ref={ref}
        className="products__list hide_scroll"
      >
        {products?.map((product) => (
          <Product key={product.id} product={product} />
        ))}
      </div>
    </div>
  );
}

export default BestSeller;

const Product = ({ product }) => {
  const navigate = useNavigate();
  const price = usePriceFormatter(product?.price);
  const costumer = useAuthStore((state) => state.costumer);
  const addLike = useLikeStore((state) => state.addLike);
  const removeLike = useLikeStore((state) => state.removeLike);
  const likedProducts = useLikeStore((state) => state.likedProducts);
  const exist = likedProducts.find((p) => p.id === product?.id);

  useEffect(() => {
    const likes = product?.likes ?? [];
    const liked = likes.includes(costumer?.id);
    if (liked && !exist) addLike(product);

    return () => {};
  }, [addLike, costumer, exist, product]);

  async function like() {
    try {
      if (!costumer) return navigate("/auth/login");
      if (!exist) addLike(product);
      else removeLike(product);
      const res = await fetchApi(`${API_URL}/products/${product.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          likes: exist
            ? (product?.likes ?? []).filter((id) => id !== costumer?.id)
            : [...(product?.likes ?? []), costumer?.id],
        }),
      });
      console.log(res);
    } catch (error) {
      console.log(error);
      if (!exist) removeLike(product);
      else addLike(product);
    }
  }

  return (
    <div className="product">
      <div className="product__indicator">{product?.category}</div>
      <div>
        <div className="product__top">
          <div
            style={{
              backgroundImage: `url('${product.image}')`,
            }}
            className="product__top__image"
          ></div>

          <Link className="product__top__title">{product?.name}</Link>
          <div className="product__top__location">
            <i>
              <GrLocation />
            </i>
            <span>{product?.location ?? "Dakar"}</span>
          </div>
          {product?.price && <div className="product__top__price">{price}</div>}
          {product?.weight && (
            <div className="product__top__weight">{`${product?.weight} KG`}</div>
          )}
        </div>
      </div>
      <div className="product__bottom">
        <div className="product__bottom__action">
          <button className="product__bottom__action__btn">
            <AiOutlineEye size={17} />
          </button>
          <button className="product__bottom__action__btn">
            <LuRepeat size={17} />
          </button>
          <button className="product__bottom__action__btn" onClick={like}>
            {exist ? <FaHeart color="#3140af" /> : <AiOutlineHeart size={17} />}
          </button>
        </div>
        {/* <div className="product__bottom__vues">1020 Vues</div> */}
      </div>
    </div>
  );
};
