import React from 'react';

const MiddleBanner = () => {
  return (
    <div className="middle_banner">
      <h3>
       
      </h3>
    </div>
  );
};

export default MiddleBanner;
