/**
 *
 * @param {string} url
 * @param {Record<string,string|number>} params
 */
export async function getFetch(url, params) {
  const query = new URLSearchParams(params).toString();

  const response = await fetch(`${url}?${query}`);

  if (!response.ok) throw new Error(response.statusText);

  return await response.json();
}

/**
 *
 * @param {string} url
 * @param {RequestInit&{params?:Record<string,string|number>}} options
 * @returns {Promise<any>}
 */
export async function fetchApi(url, options = {}) {
  let query = '';
  let token = localStorage.getItem('token');

  if (options?.params) {
    query = new URLSearchParams(options.params).toString();
    delete options.params;
  }

  if (token) {
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${token}`,
    };
  }



  const res = await fetch(`${url}?${query}`, options);

  const contentType = res.headers.get('content-type');

  if (!res.ok) {
    if (res.status === 401 || res.status === 403) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
    }

    if (contentType?.includes('application/json')) {
      const error = await res.json();
      throw new Error(error.error);
    }

    const error = await res.text();

    throw new Error(error);
  }

  if (contentType?.includes('application/json')) {
    return await res.json();
  }

  return await res.text();
}
