import React from "react";
import OrderSummary from "./OrderSummary";
import OrderItems from "./OrderItems";
import { useCounter } from "@uidotdev/usehooks";

function OrderPage() {
  const [quantity, { increment, decrement }] = useCounter(1, {
    min: 1,
    max: 10,
  });
  return (
    <div className="order_page">
      <OrderSummary quantity={quantity} />
      <OrderItems
        quantity={quantity}
        increment={increment}
        decrement={decrement}
      />
    </div>
  );
}

export default OrderPage;
