import { useEffect, useRef, useState } from 'react';

export default function useHorizontalScroll() {
  const [scrollWidth, setScrollWidth] = useState(0);
  const [currentScroll, setCurrentScroll] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    if (!ref.current) return;

    update();
    // listen if scroll width changes
    const ro = new ResizeObserver(update);
    ro.observe(ref.current);
  }, [ref]);

  function update() {
    const element = ref.current;
    if (!element) return;

    setScrollWidth(element.scrollWidth - element.clientWidth);
    setCurrentScroll(element.scrollLeft);
  }

  function handleScroll(e) {
    const element = e.target;
    if (!element) return;

    setCurrentScroll(Math.round(element.scrollLeft));
  }

  return { ref, scrollWidth, currentScroll, update, handleScroll };
}
