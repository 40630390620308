import { DELIVERY_URL } from '../constants/api';
import { getFetch } from '../libs/fetch';

export async function getRegions() {
  return getFetch(`${DELIVERY_URL}/regions?_sort=region_name&_order=asc`);
}

export async function getDakarZones() {
  return getFetch(`${DELIVERY_URL}/dakar?_sort=district&_order=asc`);
}

export async function getCountries() {
  return getFetch(`${DELIVERY_URL}/countries?_sort=name&_order=asc`);
}

export async function getGPs() {
  return getFetch(`${DELIVERY_URL}/GP`);
}
