import React, { useEffect } from "react";
import Product from "../product/Product";
import usePagination from "../../hooks/usePagination.js";

function AssociatedProducts() {
  const { products, showMore, loadMore, toggleShowMore } = usePagination(
    10,
    10
  );
  useEffect(() => {
    if (showMore) {
      window.scrollTo(0, window.scrollY - 868);
    }
  }, [showMore]);
  return (
    <div className="associated-products-page">
      <h3>Recherches associées</h3>
      <div className="associated-products-page__products">
        {products.map((product) => (
          <Product key={product.id} product={product} />
        ))}
      </div>
      {showMore ? (
        <button className="associated-products-page__button" onClick={loadMore}>
          Voir plus
        </button>
      ) : (
        <button
          className="associated-products-page__button"
          onClick={toggleShowMore}
        >
          Voir moins
        </button>
      )}
    </div>
  );
}

export default AssociatedProducts;
