import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { RiVisaLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { API_URL } from '../../constants/api';
import { useLivraison } from '../../hooks/useLivraison';
import usePriceFormatter from '../../hooks/usePriceFormatter';
import { fetchApi } from '../../libs/fetch';
import { useCartStore } from '../../stores/cart';
import { useAuthStore } from '../../stores/useAuthStore';

function OrderSummary({ quantity }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const orderInfo = JSON.parse(localStorage.getItem('order'));
  const carts = JSON.parse(localStorage.getItem('cart'));
  const paymentMethod = JSON.parse(localStorage.getItem('paymentMethod'));
  const costumer = useAuthStore((state) => state.costumer);
  const clearCart = useCartStore((state) => state.clear);
  const totalPrice = carts?.reduce((acc, curr) => {
    return acc + curr?.price * (curr?.quantity + quantity - 1);
  }, 0);
  const totalWeight = carts?.reduce((acc, curr) => {
    return acc + curr?.weight * (curr?.quantity + quantity - 1);
  }, 0);
  const nbArticles = carts?.reduce((acc, curr) => {
    return acc + curr?.quantity;
  }, 0);
  const formattedPrice = usePriceFormatter(totalPrice);
  const { livraison, mode } = useLivraison({
    orderInfo: orderInfo,
    totalWeight: totalWeight,
  });

  console.log(livraison);

  async function valideOrder() {
    try {
      if (!costumer) return navigate('/auth/login');
      let omCode = '';

      if (paymentMethod.code === 'om') {
        const { value: formValues } = await Swal.fire({
          imageUrl: paymentMethod.image,
          imageWidth: '128px',
          imageHeight: '128px',
          html: `
            <p class="om-help-text">Composer le <b>#144#391*VOTRE_PIN_CODE#</b> pour générer un code de paiement orange money.</p>
            <input id="om-code" class="swal2-input" placeholder="Entrez le code de paiement" required>
          `,
          focusConfirm: false,
          confirmButtonText: 'Confirmer',
          preConfirm: () => {
            return [document.getElementById('om-code').value];
          },
          padding: '1.5rem 0',
        });

        if (!formValues || formValues?.length < 0) return;

        if (formValues[0]?.length < 5 || isNaN(parseInt(formValues[0])))
          return toast.error('Code de paiement invalid!');

        omCode = formValues[0];
      }

      setLoading(true);
      const res = await fetchApi(`${API_URL}/orders`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          customer_id: costumer?.id,
          products: carts.map((cart) => ({
            id: cart?.id,
            quantity: +cart?.quantity,
          })),
          client_name: orderInfo?.name,
          client_phone: orderInfo?.phone,
          deliver_zone: orderInfo?.deliverZone || orderInfo?.country,
          gp_name: orderInfo?.gpName ?? '',
          gp_phone: orderInfo?.gpPhone ?? '',
          deliver_region: orderInfo?.region?.region_name ?? '',
          dakar_zone: orderInfo?.dakarZone ?? '',
          total: totalPrice + livraison,
          deposit: 0,
          rest: 0,
          payment_method: paymentMethod.code,
          omCode,
        }),
      });
      setLoading(false);

      if ('url' in res && (paymentMethod.code === 'paydunya' || paymentMethod.code === 'wave')) {
        window.location.href = res.url;
        clearCart();
        localStorage.removeItem('order');
        navigate('/shopping-cart');
        toast.success('Votre commande a été validée avec succès');
        return;
      }

      if (paymentMethod.code === 'om' && res.success) {
        clearCart();
        localStorage.removeItem('order');
        navigate('/shopping-cart');
        toast.success('Votre commande a été validée avec succès');
        return;
      } else if (paymentMethod.code === 'om' && !res.success) {
        toast.error(res.message);
        return;
      }

      toast.error('Une erreur est survenue. Veuillez réessayer');
    } catch (error) {
      setLoading(false)
      console.log(error);
      toast.error('Une erreur est survenue. Veuillez réessayer');
    }
  }
  return (
    <div className="order_summary">
      <div className="order_summary__header">
        <h3>Veuillez confirmer et soumettre votre commande</h3>
        <p>
          <span>**</span>Le paiement fait par le client sera séquestré jusqu'à réception et validation du
          produit avant que l'argent ne soit donné au vendeur
        </p>
      </div>
      <div className="order_summary__body">
        <div className="order_summary__body__payment_method">
          <div className="order_summary__body__payment_method__title">
            <p>Expédition et paiement</p>
          </div>
          <div className="order_summary__body__payment_method__content">
            <div className="order_summary__body__payment_method__content__header">
              <p>Informations client</p>
              <p>Mode de paiement</p>
            </div>
            <div className="order_summary__body__payment_method__content__info">
              <p>
                {orderInfo?.name} <br /> {orderInfo?.city}, {orderInfo?.phone}
              </p>
              {orderInfo.cardNumber ? (
                <p>
                  <span>
                    <RiVisaLine />
                    &nbsp;
                  </span>
                  {orderInfo.cardNumber} &nbsp; {orderInfo.cardCvcCode} Exp: {orderInfo.cardDate}
                </p>
              ) : (
                <div>
                  <img src={paymentMethod.image} alt="" />
                  <p>{paymentMethod?.name}</p>
                </div>
              )}
            </div>
            <div className="order_summary__body__payment_method__content__actions">
              <button
                onClick={() =>
                  window.history.back({
                    pathname: '/check-out',
                    state: { from: 'order' },
                  })
                }
              >
                Changement
              </button>
              <button
                onClick={() =>
                  window.history.back({
                    pathname: '/shopping-cart',
                    state: { from: 'order' },
                  })
                }
              >
                Changement
              </button>
            </div>
          </div>
        </div>
        <div className="order_summary__body__order_info_container">
          <div className="order_summary__body__order_info_container__order_info">
            <div className="order_summary__body__order_info_container__order_info__title">
              <p>Expédition et paiement</p>
            </div>
            <div className="order_summary__body__order_info_container__order_info__content">
              <div className="order_summary__body__order_info_container__order_info__content__total_items">
                <p>Total des articles</p>
                <p>{formattedPrice}</p>
              </div>
              <div className="order_summary__body__order_info_container__order_info__content__delivery">
                <p>
                  {mode.name === 'noGP'
                    ? `Livraison par GP : ${mode.data.name}`
                    : mode.name === 'GP'
                    ? `Livraison par GP : ${mode.data.delivery_person}`
                    : 'Livraison'}
                </p>
                <p>{usePriceFormatter(livraison)}</p>
              </div>
              <div className="order_summary__body__order_info_container__order_info__content__line"></div>
              <div className="order_summary__body__order_info_container__order_info__content__total_order">
                <p>Total de la commande ({nbArticles} articles)</p>
                <p>{usePriceFormatter(totalPrice + livraison)}</p>
              </div>
            </div>
          </div>
          <div className="order_summary__body__order_info_container__order_info__actions">
            <button disabled={loading} onClick={valideOrder}>
              Passez votre commande
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderSummary;
