import React, { useEffect, useState } from 'react';
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi';
import useHorizontalScroll from '../../hooks/useHorizontalScroll';
import Product from './Product';

const Products = ({ products, title }) => {
  const scrollStep = 360;
  const { ref, scrollWidth, currentScroll, handleScroll } = useHorizontalScroll();
  const [hasNext, setHasNext] = useState(false);
  const [hasPrev, setHasPrev] = useState(false);

  useEffect(() => {
    setHasNext(currentScroll < scrollWidth && scrollWidth > 0);
    setHasPrev(currentScroll > 0);
  }, [currentScroll, scrollWidth]);

  return (
    <div className="products">
      <div className="products__toolbar">
        <h4 className="products__toolbar__title">{title}</h4>
        <div className="products__toolbar__paginator">
          <button
            onClick={() => (ref.current.scrollLeft -= scrollStep)}
            className={`products__toolbar__paginator__btn ${
              hasPrev ? 'products__toolbar__paginator__btn--active' : ''
            }`}
          >
            <BiLeftArrowAlt />
          </button>
          <button
            onClick={() => (ref.current.scrollLeft += scrollStep)}
            className={`products__toolbar__paginator__btn ${
              hasNext ? 'products__toolbar__paginator__btn--active' : ''
            }`}
          >
            <BiRightArrowAlt />
          </button>
        </div>
      </div>
      <div onScroll={handleScroll} ref={ref} className="products__list hide_scroll">
        {products?.map((product) => (
          <Product key={product.id} product={product} />
        ))}
      </div>
    </div>
  );
};

export default Products;
