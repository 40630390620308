import { useMemo } from "react";

function usePriceFormatter(
  price,
  locale = "fr-FR",
  currency = "cfa",
  maximumFractionDigits = 0
) {
  return useMemo(() => {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
      maximumFractionDigits,
    }).format(price);
  }, [price, locale, currency, maximumFractionDigits]);
}

export default usePriceFormatter;
