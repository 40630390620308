import { create } from 'zustand';

export const useAuthStore = (() => {
  return create((set) => ({
    user: null,
    costumer: null,
    loading: true,
    setUser: (user) => set({ user }),
    setCostumer: (costumer) => set({ costumer }),
    setLoading: (loading) => set({ loading }),
    logout: () => set({ user: null }),
  }));
})();
