import React, { Fragment } from 'react';

function FormLivraisonMethod({
  dakarZones,
  regions,
  countries,
  region,
  country,
  GPs,
  register,
  deliverZone,
  GP,
  errors,
  active,
}) {
  return (
    <Fragment>
      <h3>Zone de livraison</h3>
      <div className="check_out__form__group check_out__form__group--horizontal">
        <div>
          <input
            defaultChecked
            type="radio"
            value="senegal"
            id="mode1"
            {...register('deliverZone')}
          />
          <label htmlFor="mode1">Sénégal</label>
        </div>
        <div>
          <input type="radio" value="outside" id="mode2" {...register('deliverZone')} />
          <label htmlFor="mode2">Extérieur</label>
        </div>
      </div>
      <div className="check_out__form__group">
        {deliverZone === 'senegal' ? (
          <select
            aria-invalid={errors.region ? 'true' : 'false'}
            {...register('region', {
              required: active,
            })}>
            <option value="">Choisir une région</option>
            <option value="Dakar">Dakar</option>
            {regions?.map((region, i) => (
              <option key={i} value={JSON.stringify(region)}>
                {region.region_name}
              </option>
            ))}
          </select>
        ) : (
          <select
            aria-invalid={errors.country ? 'true' : 'false'}
            {...register('country', {
              required: active,
            })}>
            <option value="">Choisir un pays</option>
            {countries?.map((country, i) => (
              <option key={i} value={country.name}>
                {country.name}
              </option>
            ))}
          </select>
        )}
      </div>
      {region?.toLowerCase() === 'dakar' && deliverZone === 'senegal' ? (
        <div className="check_out__form__group">
          <select
            aria-invalid={errors.dakarZone ? 'true' : 'false'}
            {...register('dakarZone', {
              required: active,
            })}>
            <option value="">Choisir un lieu de livraison</option>
            {dakarZones?.map((dz, i) => (
              <option key={i} value={JSON.stringify(dz)}>
                {dz.district}
              </option>
            ))}
          </select>
        </div>
      ) : deliverZone === 'outside' ? (
        <>
          {GPs.some((GP) => GP.country === country) ? (
            <div className="check_out__form__group">
              <select
                aria-invalid={errors.GP ? 'true' : 'false'}
                {...register('GP', {
                  required: active,
                })}>
                <option value="">Choisir un GP</option>
                <option value="noGP">Mon propre GP</option>
                {GPs?.map((gp, i) =>
                  gp.country === country ? (
                    <option key={i} value={JSON.stringify(gp)}>
                      {gp.delivery_person}
                    </option>
                  ) : (
                    ''
                  )
                )}
              </select>
            </div>
          ) : (
            ''
          )}
          {country && (!GPs.some((GP) => GP.country === country) || GP === 'noGP') ? (
            <>
              <div className="check_out__form__group">
                <label htmlFor="">
                  Nom du GP <span>*</span>
                </label>
                <input
                  aria-invalid={errors.gpName ? 'true' : 'false'}
                  type="text"
                  placeholder="GP"
                  {...register('gpName', {
                    required: active,
                  })}
                />
              </div>
              <div className="check_out__form__group">
                <label htmlFor="">
                  Numéro du GP <span>*</span>
                </label>
                <input
                  aria-invalid={errors.gpPhone ? 'true' : 'false'}
                  type="text"
                  placeholder="Téléphone"
                  {...register('gpPhone', {
                    required: active,
                  })}
                />
              </div>
            </>
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}
      <div className="check_out__form__group">
        <label htmlFor="">
          Prénom et Nom <span>*</span>
        </label>
        <input
          aria-invalid={errors.name ? 'true' : 'false'}
          type="text"
          placeholder="Prénom et Nom"
          {...register('name', { required: active, pattern: /^[a-zA-ZÀ-ÿ' -]{2,30}$/i })}
        />
      </div>
      <div className="check_out__form__group">
        <label htmlFor="">
          Téléphone <span>*</span>
        </label>
        <input
          type="text"
          aria-invalid={errors.phone ? 'true' : 'false'}
          placeholder="Téléphone"
          {...register('phone', {
            required: active,
            pattern: /^(0[1-9]\d{8}|[1-9]\d{8})$/i,
          })}
        />
      </div>
    </Fragment>
  );
}

export default FormLivraisonMethod;
