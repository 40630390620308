import React, { useState } from 'react';
import { useFetch, useInterval } from 'usehooks-ts';
import { API_URL } from '../../constants/api';
import useSwipper from '../../hooks/useSwipper';

const Reduction = () => {
  const { data } = useFetch(`${API_URL}/top-ads`);
  const { index, next } = useSwipper(data?.data?.length);
  const [isPlayingAds] = useState(true);
  const delay = 10_000;

  useInterval(next, isPlayingAds ? delay : null);
  return data?.data?.map((item, i) => {
    return i === index ? (
      <div key={index} className={`reduction reduction--show`}>
        <h3 className="animate-line-up">{item?.title}</h3>
        <p className="animate-line-up">{item?.body}</p>
      </div>
    ) : null;
  });
};

export default Reduction;
