import React, { Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetch } from 'usehooks-ts';
import AssociatedProducts from '../../components/associated-products/AssociatedProducts';
import { API_URL } from '../../constants/api';
import Description from './Description';
import ProductDetails from './ProductDetails';

const DetailsPageLoading = () => {
  return (
    <div className="details-page-loader">
      <span class="loader"></span>
    </div>
  );
};

function DetailsPage() {
  let navigate = useNavigate();
  const { id } = useParams();
  const { data: product, error: productError } = useFetch(id ? `${API_URL}/products/${id}` : null);

  return (
    <Fragment>
      <div className="details-page">
        {product && <ProductDetails product={product?.data} />}
        {product && <Description description={product?.data.description} />}
        {productError && (
          <div className="error">
            <h2>Oops! Something went wrong.</h2>
            <button onClick={() => navigate(-1)}>Go back</button>
          </div>
        )}
        {!product && !productError && <DetailsPageLoading />}
      </div>
      {product && <AssociatedProducts />}
    </Fragment>
  );
}

export default DetailsPage;
