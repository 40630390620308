import React, { Fragment, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import 'react-phone-input-2/lib/style.css';
import { RouterProvider } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './assets/scss/main.scss';
import { API_URL } from './constants/api';
import { fetchApi } from './libs/fetch';
import { auth } from './libs/firebase';
import reportWebVitals from './reportWebVitals';
import { router } from './routes/router';
import { useAuthStore } from './stores/useAuthStore';

const root = ReactDOM.createRoot(document.getElementById('root'));

function App() {
  const setUser = useAuthStore((state) => state.setUser);
  const setCostumer = useAuthStore((state) => state.setCostumer);
  const setLoading = useAuthStore((state) => state.setLoading);

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      const costumer = await getCostumer(user?.uid);
      setUser(user ?? null);
      setCostumer(costumer ?? null);
      setLoading(false);
    });
    return () => {};
  }, [setCostumer, setLoading, setUser]);

  function getCostumer(uid) {
    return fetchApi(`${API_URL}/agents`, { params: { filtre: `{"uid":"${uid}"}` } }).then(
      (agents) => agents?.data[0],
    );
  }
  return (
    <Fragment>
      <RouterProvider router={router} />
    </Fragment>
  );
}
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
