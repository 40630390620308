import React from 'react';

const NewLetters = () => {
  return (
    <div className="new_letters">
      <div className="new_letters__title">
        Oui ! Envoyez-moi des offres exclusives, des idées uniques et des conseils personnalisés pour
        acheter et vendre
      </div>
      <form className="new_letters__form">
        <div className="new_letters__form__input">
          <input type="email" placeholder="Enter your email" />
          <button type="submit" className="new_letters__form__btn">Subscribe</button>
        </div>
      </form>
    </div>
  );
};

export default NewLetters;
