import { API_URL } from '../constants/api';
import { getFetch } from '../libs/fetch';

export async function getProducts(params) {
  return getFetch(`${API_URL}/products`, params);
}

export async function getProductById(id, params) {
  return getFetch(`${API_URL}/products/${id}`, params);
}

export async function searchProductByName(name) {
  const params = {
    _sort: 'name',
    _order: 'asc',
    q: JSON.stringify({name: name})
  }
  return getFetch(`${API_URL}/products`, params);
}

export async function getProductsByCategory(categoryId) {
  const params = {
    _sort: 'categoryId',
    _order: 'asc',
    q: JSON.stringify({categoryId: categoryId})
  }
  return getFetch(`${API_URL}/products`, params);
}
